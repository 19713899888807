import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  Button, Form, FormGroup, Label, Input, FormText,
} from 'reactstrap';
import Blog01 from '../images/blog/blog.jpg';
import Layout from '../layouts/index';
import BlogArticle from '../components/Blog';
import MiniStarterEN from '../images/mini-starter-en.svg';
import MiniStarterPT from '../images/mini-starter-pt.svg';
import MiniStarterES from '../images/mini-starter-es.svg';
import Firma1 from '../images/firma-1.png';
import Firma2 from '../images/firma-2.png';
import NegotiationES from '../images/negotiation-es.png';

const miniStarterImage = {
  es: MiniStarterES,
  en: MiniStarterEN,
  pt: MiniStarterPT,
};

function ElectronicSignaturePage({ pageContext: { locale }, location}) {
    const { t } = useTranslation()
  return (
    <Layout id="landing" locale={locale} bodyClass="page-home landing bg-light" inverted border location={location}>
      <section className="bg-light">
        <div className="container text-center">
          <div className="heading text-center mx-auto">
            <h1>Firma Electrónica vs Firma Electrónica Avanzada</h1>
            <p className="lead text-muted">Hablaremos de las diferencias que hay con cada una, empecemos por la firma electrónica para  que sepas la seguridad que TRATO brinda a tus contratos</p>
          </div>
        </div>
      </section>
      <section className="bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img src={Firma1} className="img-fluid" alt="Firma Electrónica vs Firma Electrónica Avanzada" />
            </div>
            <div className="col-lg-6">
              <h2 className="mb-3">Que es una firma electrónica</h2>
              <ul className="list-unstyled">
                <li className="text-muted mb-2"><FontAwesomeIcon icon={faCheck} className="text-primary mr-1" /> Una firma electrónica es el medio de manifestación de la voluntad, consentimiento cuya finalidad es identificar al firmante e indicar que aprueba la información contenida en el documento electrónico.</li>
                <li className="text-muted mb-2"><FontAwesomeIcon icon={faCheck} className="text-primary mr-1" /> El Código de Comercio Mexicano reconoce los efectos jurídicos, validez y fuerza obligatoria  a través de un mensajes de datos. Esto quiere decir que la ley de la Firma Electrónica Avanzada, tendrá el mismo valor probatorio que un documento físico.</li>
                <li className="text-muted"><FontAwesomeIcon icon={faCheck} className="text-primary mr-1" /> El Código de Comercio de México en su artículo 89 declara que la ley o las partes requieran de una firma en relación con mensajes de datos, dicho requisito podrá ser realizado a través de una firma electrónica. </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="mb-3">Firma Electrónica Avanzada</h2>
              <ul className="list-unstyled">
                <li className="text-muted mb-2"><FontAwesomeIcon icon={faCheck} className="mr-1 text-primary" /> La firma digital (mejor conocida en México como firma electrónica avanzada) consiste en la aplicación de un algoritmo matemático a una representación gráfica del nombre de una persona física o moral.</li>
                <li className="text-muted mb-2"><FontAwesomeIcon icon={faCheck} className="mr-1 text-primary" /> La firma electrónica avanzada utiliza otros aspectos complementarios, por ejemplo, los datos biométricos o de geolocalización, para poder identificar al firmante.</li>
                <li className="text-muted"><FontAwesomeIcon icon={faCheck} className="mr-1 text-primary" /> La principal función de los PSC (Prestador de Servicios de Certificación)  es llevar a cabo los servicios de Firma Electrónica Avanzada de Emisión de Certificados Digitales de Firma Electrónica Avanzada, Constancias de Conservación de Mensajes de Datos de Conformidad con la NOM151-SCFI-2016, Sellado Digital de Tiempo y Digitalización de documentos de Conformidad con la NOM151-SCFI-2016, en términos y con los requisitos que establece el Código de Comercio.</li>
              </ul>
            </div>
            <div className="col-lg-6">
              <img src={NegotiationES} className="img-fluid" alt="Firma Electrónica vs Firma Electrónica Avanzada" />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img src={Firma2} className="img-fluid" alt="Firma Electrónica vs Firma Electrónica Avanzada" />
            </div>
            <div className="col-lg-6">
              <h2 className="mb-3">Firma Electronica (FE) vs Firma Electronica Avanzada (FEA)</h2>
              <p className="text-muted">Las firmas electrónicas tienen los mismos efectos jurídicos que la firma autógrafa y pueden ser utilizadas como prueba en juicios y arbitrajes.</p>
              <ul className="list-unstyled">
                <li className="text-muted mb-2"><FontAwesomeIcon icon={faCheck} className="mr-1 text-primary" /> Agilidad para poder hacerlo en cualquier momento.</li>
                <li className="text-muted mb-2"><FontAwesomeIcon icon={faCheck} className="mr-1 text-primary" /> No necesita imprimir los documentos y escanearlos.</li>
                <li className="text-muted mb-2"><FontAwesomeIcon icon={faCheck} className="mr-1 text-primary" /> Puede activar la póliza inmediatamente.</li>
                <li className="text-muted"><FontAwesomeIcon icon={faCheck} className="mr-1 text-primary" /> Tiene la misma validez jurídica que una firma autógrafa plasmada en papel.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="call-to-action bg-light">
        <div className="container pt-4">
          <p className="lead text-muted text-center mb-5">En TRATO contamos con los máximos mecanismos de seguridad para que blindes tus contratos y estes protegido en cualquier momento, puedes empezar a firmar y/o agendar una demo con algunos de nuestros abogados de forma gratuita.</p>
          <div className="card bg-primary rounded text-light">
            <div className="row">
              <div className="col-lg-7 d-flex">
                <div className="card-cta align-self-center mx-auto px-3">
                  <h3 className="font-weight-bold mb-3">{t('newCTA.heading')}</h3>
                  <p className="lead mb-3">{t('newCTA.text')}</p>
                  <a href="https://starter.trato.io/" className="btn btn-light" target="_blank">{t('newCTA.button')}</a>
                </div>
              </div>
              <div className="col-lg-5 text-center">
                <img src={miniStarterImage[locale]} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>


  );
}

export default ElectronicSignaturePage;
